/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from "react"
import loadable from "@loadable/component";
import { useStaticQuery, graphql } from "gatsby"

//  import Header from "./header"
//  import "./layout.css"
import "../styles/main.scss";
import "animate.css/animate.min.css";
import Header from "./Header/Header";
// const Header = loadable(() => import("./Header/Header"));
const Footer = loadable(() => import("./Footer/Footer"));

const Layout = ({ children, popularSearch, footerContact, customClass }) => {

  const data = useStaticQuery(graphql`
  query {
    strapiThemeConfig {
      theme_style
    }
  }
`);

  const theme_style = data.strapiThemeConfig?.theme_style;


  return (
    <div className={customClass}>
      <Header headerDubai={theme_style === "dubai" ? "header-dubai" : ""} />
      <main>{children}</main>
      <Footer popularSearch={popularSearch} footerContact={footerContact} footerDubai={theme_style === "dubai" ? "footer-dubai" : ""} />
    </div>
  )
}

export default Layout